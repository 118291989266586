import * as React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getClassNames } from './index.classNames';
import Scrollbars from 'react-custom-scrollbars';
import { Checkbox, ComboBox, DatePicker, DirectionalHint, Dropdown, FontIcon, FontSizes, IDropdownOption, IPersonaProps, IRenderFunction, ITag, ITextFieldProps, KeyCodes, Label, NormalPeoplePicker, SearchBox, Separator, Spinner, SpinnerSize, TagPicker, TextField, Toggle, TooltipHost, ValidationState } from 'office-ui-fabric-react';
import { CommandActions } from '../../../redux/actions/filters';
import { CommandActions as DocumentsCommandActions} from '../../../redux/actions/documents';
import _, { filter } from 'lodash';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Tag, WithContext as ReactTags } from 'react-tag-input';
import debounce from 'debounce';
import { getFilterClientNumber, getFilterCommented, getFilterDiscipline, getFilterDocType, getFilterDocumentNumber, getFilterEngPhase, getFilterEntity, getFilterFolder, getFilterFrom, getFilterIsForInternalUse, getFilterPbs, getFilterPurpose, getFilterPwFilename, getFilterDocumentIndex, getFilterSheet, getFilterShowAllVersions, getFilterStatus, getFilterTdNumber, getFilterTo, getFilterTransmittalNumber, getFilterType, getSelectedFilter, getStatus, getFilterPartner, getFilterCustomer, getFilterSapType, getFilterSapPart, getFilterLanguage, getFilterFileType, getProjectDocumentNumber, getFilterSupplier, getFilterSupplierDocIndex, getFilterSupplierDocNumber, getFilterTosId, getFilterGeneric, getFilterCustomerDocIndex, getFilterCustomerDocNumber, getFilterTransmittalId } from '../../../redux/reducers/filters';
import { Type } from '../../../models/type';
import { Status, StatusEnum } from '../../../models/status';
import { formatDate, getTypesDropdownOptions, getEngPhaseDropdownOptions, getEntityDropdownOptions, getHistoryName, getPurposeDropdownOptions, getDisciplineDropdownOptions, getDocTypesDropdownOptions, getContextualFilters, getStakeHoldersDropdownOptions, getFileTypesDropdownOptions, getPurposeLabel } from '../../../utils/functions';
import { FilterComponent, Filters } from '../../../models/filters';
import { ExternalRoleActionsType, FiltersType, getTheme, InternalRoleActionsType, isExternalApp } from '../../../utils/costants';
import NumberFormat from 'react-number-format';
import { getTypes, getEngPhases, getEntities, getPurposes, getStatusValues, getDisciplines, getPbs, getFolders } from '../../../redux/reducers/sharedData';
import { getLoader } from '../../../redux/selectors/loader';
import { Purpose } from '../../../models/purpose';
import { Entity } from '../../../models/entity';
import { EngPhase } from '../../../models/engPhase';
import { Discipline } from '../../../models/discipline';
import { Popup as HierarchyExplorer } from '@pwbapps/hierarchyexplorer';
import { Pbs } from '../../../models/pbs';
import CornerArrow from '../../../images/cornerArrow.png';
import { getField } from '../../reduxFields/reduxGeneralField';
import { DocType } from '../../../models/docType';
import { getStatusDropdownOptions } from '../../../utils/statusFunctions';
import { getSelectedProject, getSelectedProjectCustomers, getSelectedProjectPartners, getSelectedProjectSuppliers } from '../../../redux/selectors/project';
import { Project } from '../../../models/project';
import { Folder } from '../../../models/folder';
import { StakeHolder } from '../../../models/stakeHolder';
import { getSelectedProjectUserActions } from '../../../redux/selectors/auth';
import { ModalCommandActions } from '../../../redux/actions/modal';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { getDarkModeEnabled } from '../../../redux/reducers/project';

const SheetReader = getField((state: any) => getFilterSheet(state));
const DocumentIndexReader = getField((state: any) => getFilterDocumentIndex(state));
const TransmittalIdReader = getField((state: any) => getFilterTransmittalId(state));
const TransmittalNumberReader = getField((state: any) => getFilterTransmittalNumber(state));
const StatusReader = getField((state: any) => getFilterStatus(state));
const TypeReader = getField((state: any) => getFilterType(state));
const PartnerReader = getField((state: any) => getFilterPartner(state));
const CustomerReader = getField((state: any) => getFilterCustomer(state));
const SupplierReader = getField((state: any) => getFilterSupplier(state));
const PurposeReader = getField((state: any) => getFilterPurpose(state));
const EntityReader = getField((state: any) => getFilterEntity(state));
const EngPhaseReader = getField((state: any) => getFilterEngPhase(state));
const DocTypeReader = getField((state: any) => getFilterDocType(state));
const PbsReader = getField((state: any) => getFilterPbs(state));
const FolderReader = getField((state: any) => getFilterFolder(state));
const ShowAllVersionsReader = getField((state: any) => getFilterShowAllVersions(state));
const CommentedReader = getField((state: any) => getFilterCommented(state));
const SapPartReader = getField((state: any) => getFilterSapPart(state));
const SapTypeReader = getField((state: any) => getFilterSapType(state));
const LanguageReader = getField((state: any) => getFilterLanguage(state));
const FileTypeReader = getField((state: any) => getFilterFileType(state));
const ProjectDocumentNumberReader = getField((state: any) => getProjectDocumentNumber(state));
const SupplierDocIndexReader = getField((state: any) => getFilterSupplierDocIndex(state));
const SupplierDocNumberReader = getField((state: any) => getFilterSupplierDocNumber(state));
const GenericReader = getField((state: any) => getFilterGeneric(state));
const CustomerDocIndexReader = getField((state: any) => getFilterCustomerDocIndex(state));
const CustomerDocNumberReader = getField((state: any) => getFilterCustomerDocNumber(state));

export interface FiltersState { isLoadingPics: boolean }
export interface FiltersProps extends RouteComponentProps, WithTranslation { 
    userProjectActions: InternalRoleActionsType[] | ExternalRoleActionsType[],
    isCallout?: boolean,
    selectedFilter?: FiltersType,
    statusValues: Status[],
    types: Type[],
    purposes: Purpose[],
    entities: Entity[],
    engPhases: EngPhase[],
    disciplines: Discipline[],
    from?: Date,
    to?: Date,
    documentNumber: string[],
    tosId: string[],
    pbs: Pbs[],
    discipline?: Discipline
    selectedProject?: Project,
    folders: Folder[],
    partners: StakeHolder[],
    customers: StakeHolder[],
    suppliers: StakeHolder[],
    fileType?: string,
    loadingStatus: boolean,
    loadingTypes: boolean,
    loadingPurposes: boolean,
    loadingEntities: boolean,
    loadingEngPhases: boolean,
    loadingDisciplinesAndDocTypes: boolean,
    loadingPbs: boolean,
    loadingFolders: boolean,
    loadingStakeHolders: boolean,
    darkModeEnabled: boolean,
    fetchDocuments: typeof DocumentsCommandActions.fetchDocuments,
    setDocumentNumber: typeof CommandActions.setDocumentNumber,
    setTosId: typeof CommandActions.setTosId,
    setSheet: typeof CommandActions.setSheet,
    setDocumentIndex: typeof CommandActions.setDocumentIndex,
    setClientNumber: typeof CommandActions.setClientNumber,
    setTdNumber: typeof CommandActions.setTdNumber,
    setTransmittalId: typeof CommandActions.setTransmittalId,
    setTransmittalNumber: typeof CommandActions.setTransmittalNumber,
    setStatus: typeof CommandActions.setStatus,
    setType: typeof CommandActions.setType,
    setPurpose: typeof CommandActions.setPurpose,
    setEntity: typeof CommandActions.setEntity,
    setEngPhase: typeof CommandActions.setEngPhase,
    setFrom: typeof CommandActions.setFrom,
    setTo: typeof CommandActions.setTo,
    setPwFilename: typeof CommandActions.setPwFilename,
    setCommented: typeof CommandActions.setCommented,
    setDiscipline: typeof CommandActions.setDiscipline,
    setDocType: typeof CommandActions.setDocType,
    setPbs: typeof CommandActions.setPbs,
    setFolder: typeof CommandActions.setFolder,
    setIsForInternalUse: typeof CommandActions.setIsForInternalUse,
    setShowAllVersions: typeof CommandActions.setShowAllVersions,
    setPartner: typeof CommandActions.setPartner,
    setCustomer: typeof CommandActions.setCustomer,
    setSupplier: typeof CommandActions.setSupplier,
    setSapPart: typeof CommandActions.setSapPart,
    setSapType: typeof CommandActions.setSapType,
    setLanguage: typeof CommandActions.setLanguage,
    setFileType: typeof CommandActions.setFileType,
    setSupplierDocIndex: typeof CommandActions.setSupplierDocIndex,
    setSupplierDocNumber: typeof CommandActions.setSupplierDocNumber,
    setProjectDocumentNumber: typeof CommandActions.setProjectDocumentNumber,
    setCustomerDocIndex: typeof CommandActions.setCustomerDocIndex,
    setCustomerDocNumber: typeof CommandActions.setCustomerDocNumber,
    setGeneric: typeof CommandActions.setGeneric,
    openGenericModal: typeof ModalCommandActions.openModal,
}

class FiltersClass extends React.Component<FiltersProps> {
    debouncedFetchDocuments: any
    
    constructor(props: FiltersProps) {
        super(props);

        this.debouncedFetchDocuments = debounce((e: any, value: string) => {
            const {fetchDocuments} = this.props;
            fetchDocuments({});
		}, 500);

        this.state = {
            isLoadingPics: false
        }
    }

    _onRenderCategoryOption = (option?: IDropdownOption): JSX.Element => {
        const { isCallout, darkModeEnabled } = this.props;
        const styles = getClassNames(isCallout ? true : false, getTheme(darkModeEnabled));
        return (
            <div key={(option) ? option.key : undefined} className={styles.dropDownItem}>
                {option && (option as any).icon && <FontIcon style={{marginRight: 10, fontSize: FontSizes.xLarge, color: (option) ? (option as any).color : undefined}} iconName={(option) ? (option as any).icon : undefined} />}
                <span>{(option) ? option.text : undefined}</span>
            </div>     
        );
    };

    _onRenderCategoryTitle = (options?: IDropdownOption[]): JSX.Element => {
        const option = (options && options.length > 0) ? options[0] : undefined;
        return this._onRenderCategoryOption(option);
    };

    addDocumentNumberTag = (tag: Tag) => {
        const { setDocumentNumber, documentNumber } = this.props;
        setDocumentNumber({value: (tag && tag.id) ? [...documentNumber, tag.id] : documentNumber});
        this.debouncedFetchDocuments();
    }
    removeDocumentNumberTag = (index: number) => {
        const { setDocumentNumber, documentNumber } = this.props;
        setDocumentNumber({value: _.filter(documentNumber, (dn, i) =>  index !== i)});
        this.debouncedFetchDocuments();
    }

    addTosIdTag = (tag: Tag) => {
        const { setTosId, tosId } = this.props;
        setTosId({value: (tag && tag.id) ? [...tosId, tag.id] : tosId});
        this.debouncedFetchDocuments();
    }
    removeTosIdTag = (index: number) => {
        const { setTosId, tosId } = this.props;
        setTosId({value: _.filter(tosId, (dn, i) =>  index !== i)});
        this.debouncedFetchDocuments();
    }

    setFolderValueFunction = (item?: any, checked?: any) =>{
        const { setFolder } = this.props;
        if(item)
            setFolder({value: (checked) ? item : undefined});
        else 
            setFolder({value: undefined});
        this.debouncedFetchDocuments();
    }

    onRenderFolderSuffix: IRenderFunction<ITextFieldProps> = (props, defaultRender) => {
        const { t } = this.props;
        return (      
            <FolderReader>{(reduxValue?: Folder) =>   
                <TooltipHost directionalHint={DirectionalHint.rightCenter} content={(reduxValue) ? t("Remove Folder") : ""}>
                    <div onClick={(reduxValue) ? (e) => {this.setFolderValueFunction(undefined); e.stopPropagation()} : () => {}}>
                        <FontIcon iconName={"Cancel"} style={{cursor: (reduxValue) ? "pointer" : "default" }}  /> 
                    </div>
                </TooltipHost>          
            }</FolderReader>  
        )
    };

    getGenericComponent = (): FilterComponent => {
        const { setGeneric, t } = this.props;
        let genericComponent: FilterComponent = {
            id: '_generic',
            component:
                <GenericReader>{(reduxValue?: string) =>   
                    <TextField 
                        key={'_generic'}
                        label={t('Generic search')}
                        autoComplete={'off'} 
                        value={reduxValue || ''} 
                        onChange={(e, value) => { 
                            setGeneric({value});
                            this.debouncedFetchDocuments();
                        }}
                        styles={{root: {marginLeft: 5, minWidth: 260}}}
                    />       
                }</GenericReader>    
        };
        return genericComponent;
    }

    getMainFilters = (): FilterComponent[] => {
        const { isCallout, history, selectedFilter, statusValues, loadingStatus, from, to, documentNumber, loadingFolders, folders, selectedProject, userProjectActions, tosId, t, darkModeEnabled, loadingPurposes, purposes,
                setFrom, setTo, setStatus, setSheet, setDocumentIndex, setClientNumber, setProjectDocumentNumber, setTransmittalId, openGenericModal, setPurpose, setSapPart, setSapType, setLanguage, setSupplierDocIndex, setSupplierDocNumber,
                setCustomerDocIndex, setCustomerDocNumber, setTransmittalNumber } = this.props;
        const historyName = getHistoryName(history);
        const styles = getClassNames(isCallout ? true : false, getTheme(darkModeEnabled));
        let status_options: {key: number, text: string, icon: string, color: string}[] = [...getStatusDropdownOptions(statusValues, historyName)];
        let purposes_options: {key: number, text: string}[] = [{key: -1, text: 'None'}, ...getPurposeDropdownOptions(purposes, historyName)];
        const documentNumberTags: Tag[] = (documentNumber.length > 0) ? _.map(documentNumber, dn => {return {id: dn, text: dn}}) : [];
        const tosIdTags: Tag[] = (tosId.length > 0) ? _.map(tosId, dn => {return {id: dn, text: dn}}) : [];
     
        let statusComponent: FilterComponent = {
            id: 'status',
            component:
                <StatusReader>{(reduxValue: Status[]) =>   
                    <div style={{marginLeft: 5}}>
                        {!loadingStatus && <Label>{t('Status')}</Label>}
                        {loadingStatus && 
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Label style={{marginRight: 10}}>{t('Status')}</Label>
                                <Spinner size={SpinnerSize.small}/>
                            </div>
                        }
                        <ComboBox
                            multiSelect
                            key={'status'}
                            placeholder={t('Select a status') + '...'}
                            disabled={loadingStatus}
                            options={status_options}
                            title={_.join(_.map(reduxValue, s => s.description), ', ')}
                            onChange={(e, value) => {
                                const alredyIn = (value) ? _.find(reduxValue, s => s.id  === value.key) : undefined;
                                setStatus({value: (!alredyIn) ? [...reduxValue, _.find(statusValues, s => value && s.id === value.key) as Status] : [..._.filter(reduxValue, s => alredyIn && s.id !== alredyIn.id)]});
                                this.debouncedFetchDocuments();
                            }}
                            selectedKey={(reduxValue.length > 0) ? _.map(reduxValue, s => s.id as number) : []}    
                            onRenderOption={this._onRenderCategoryOption}
                            useComboBoxAsMenuWidth                          
                            //onRenderTitle={this._onRenderCategoryTitle}                             
                            styles={{root: {minWidth: 260}}}
                        />
                    </div>
                }</StatusReader> 
        };
        let datesComponent: FilterComponent[] = [
            {
                id: 'from',
                component:  
                    <DatePicker
                        label={t('From')}
                        value={from}
                        maxDate={(to) ? to : undefined}
                        onSelectDate={(date) => {
                            setFrom({value: (date) ? date : undefined});
                            this.debouncedFetchDocuments();
                        }}
                        formatDate={formatDate}
                        styles={{root: {marginLeft: 5, minWidth: isCallout ? 260 : undefined}}}
                    />                  
            },
            {
                id: 'to',
                component:  
                    <DatePicker
                        label={t('To')}
                        value={to}
                        minDate={(from) ? from : undefined}
                        onSelectDate={(date) => {
                            setTo({value: (date) ? date : undefined});
                            this.debouncedFetchDocuments();
                        }}
                        formatDate={formatDate}
                        styles={{root: {marginLeft: 5, minWidth: isCallout ? 260 : undefined}}}
                    />                  
            },
        ];
        let folderComponent: FilterComponent =  
        {
            id: 'folder',
            component:
            <FolderReader>{(reduxValue?: Folder) =>   
                <HierarchyExplorer 
                    readOnly={loadingFolders}
                    title={"Folder selector"}
                    items={folders}
                    onSelect={(item, checked) => this.setFolderValueFunction(item, checked)}                                             
                    selected={(reduxValue) ? [reduxValue.id] : []}
                    selectedProperty={"id"}
                    isSelectable={() => {return true}}>
                    {(ref, onClick) => (
                        <div style={{marginLeft: 5}}>
                            {!loadingFolders && 
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Label style={{marginRight: 10}}>Folders</Label>
                                    <FontIcon iconName={'Edit'} style={{color: getTheme(darkModeEnabled).palette.themePrimary, fontSize: FontSizes.large, cursor: 'pointer' }} onClick={() => {openGenericModal({id: 'folder', contextItem: {folder: reduxValue, isAdding: false, isRemoving: false}})}} />
                                </div>
                            }
                            {loadingFolders && 
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Label style={{marginRight: 10}}>Folders</Label>
                                    <Spinner size={SpinnerSize.small}/>
                                </div>
                            }
                            <div ref={ref} onClick={onClick} onFocus={onClick}>
                                <TextField 
                                    readOnly={true} 
                                    autoComplete={'off'} 
                                    value={(reduxValue) ? reduxValue.folderName : undefined} 
                                    title={(reduxValue) ? reduxValue.folderName : undefined} 
                                    onRenderSuffix={this.onRenderFolderSuffix}
                                />      
                            </div>
                        </div>
                    )}
                </HierarchyExplorer>
            }</FolderReader>  
        };
        let filtersComponents: FilterComponent[] = [
            {
                id: 'documentNumber',
                component:  
                    <div style={{marginLeft: 5, marginRight: 15}}>
                        <Label>{t('SMS Doc.Number')}</Label>
                        <ReactTags
                            tags={documentNumberTags}
                            delimiters={[KeyCodes.comma, KeyCodes.enter]}
                            handleDelete={this.removeDocumentNumberTag}
                            handleAddition={this.addDocumentNumberTag}
                            placeholder="Press enter to search"
                            inputFieldPosition="top"
                            allowDragDrop={false}
                            autofocus={false}
                            classNames={{
                                tagInputField: styles.inputField,
                                selected: styles.tagInput,
                                tag: styles.tag,
                                remove: styles.remove
                            }}
                        />
                    </div>
            },
            {
                id: 'tosId',
                component:  
                    <div style={{marginLeft: 5, marginRight: 15}}>
                        <Label>{t('TOS Node Id')}</Label>
                        <ReactTags
                            tags={tosIdTags}
                            delimiters={[KeyCodes.comma, KeyCodes.enter]}
                            handleDelete={this.removeTosIdTag}
                            handleAddition={this.addTosIdTag}
                            placeholder="Press enter to search"
                            inputFieldPosition="top"
                            allowDragDrop={false}
                            autofocus={false}
                            classNames={{
                                tagInputField: styles.inputField,
                                selected: styles.tagInput,
                                tag: styles.tag,
                                remove: styles.remove
                            }}
                        />
                    </div>
            },
            {
                id: 'sheet',
                component:        
                    <SheetReader>{(reduxValue?: number) =>   
                        <>
                            <Label style={{marginLeft: 5}}>{t('Sheet number')}</Label>
                            <NumberFormat
                                key="sheet"
                                customInput={TextField}
                                decimalScale={0}
                                value={(reduxValue) ? reduxValue : ''}
                                autoComplete={'off'}
                                onValueChange={(values) => {
                                    setSheet({value: values.floatValue});
                                    this.debouncedFetchDocuments();
                                }}
                                styles={{root: {marginLeft: 5, minWidth: 260}}}
                            />
                        </>
                    }</SheetReader>                             
            },
            {
                id: 'documentIndex',
                component:  
                    <DocumentIndexReader>{(reduxValue?: string) =>   
                        <TextField 
                            key={'documentIndex'}
                            label={t("Index")} 
                            autoComplete={'off'} 
                            value={reduxValue || ''} 
                            onChange={(e, value) => { 
                                setDocumentIndex({value});
                                this.debouncedFetchDocuments();
                            }}
                            styles={{root: {marginLeft: 5, minWidth: 260}}}
                        />    
                    }</DocumentIndexReader>                   
            },
            // {
            //     id: 'clientNumber',
            //     component:
            //         <ClientNumberReader>{(reduxValue?: string) =>   
            //             <TextField 
            //                 key={'clientNumber'}
            //                 label={t('Client number')}
            //                 autoComplete={'off'} 
            //                 value={reduxValue || ''} 
            //                 onChange={(e, value) => { 
            //                     setClientNumber({value});
            //                     this.debouncedFetchDocuments();
            //                 }}
            //                 styles={{root: {marginLeft: 5, minWidth: 260}}}
            //             />       
            //         }</ClientNumberReader>                
            // },
            {
                id: 'transmittalId' as FiltersType,
                component:  
                <TransmittalIdReader>{(reduxValue?: string) =>   
                    <>
                        <TextField 
                            key={'transmittalId'}
                            label={t('Transmittal Id')}
                            autoComplete={'off'} 
                            value={reduxValue || ''} 
                            onChange={(e, value) => { 
                                setTransmittalId({value});
                                this.debouncedFetchDocuments();
                            }}
                            styles={{root: {marginLeft: 5, minWidth: 260}}}
                        />             
                    </>
                }</TransmittalIdReader>  
            },
            {
                id: 'transmittalNumber' as FiltersType,
                component:  
                <TransmittalNumberReader>{(reduxValue?: string) =>   
                    <>
                        <TextField 
                            key={'transmittalNumber'}
                            label={t('Transmittal N°')}
                            autoComplete={'off'} 
                            value={reduxValue || ''} 
                            onChange={(e, value) => { 
                                setTransmittalNumber({value});
                                this.debouncedFetchDocuments();
                            }}
                            styles={{root: {marginLeft: 5, minWidth: 260}}}
                        />             
                    </>
                }</TransmittalNumberReader>  
            },
            {
                id: 'purpose',
                component: 
                    <PurposeReader>{(reduxValue?: Purpose) =>   
                        <div style={{marginLeft: 5}}>
                            {!loadingPurposes && <Label>{t(getPurposeLabel(historyName))}</Label>}
                            {loadingPurposes && 
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Label style={{marginRight: 10}}>{t(getPurposeLabel(historyName))}</Label>
                                    <Spinner size={SpinnerSize.small}/>
                                </div>
                            }
                            <Dropdown
                                key={getPurposeLabel(historyName)}
                                placeholder={t('Select a ' + getPurposeLabel(historyName)) + '...'}
                                disabled={loadingPurposes}
                                options={purposes_options}
                                onChange={(e, value) => {
                                    setPurpose({value: (value && value.key !== -1) ? _.find(purposes, p => p.id === value.key) : undefined});
                                    this.debouncedFetchDocuments();
                                }}
                                selectedKey={(reduxValue) ? reduxValue.id : null}                              
                                styles={{root: {minWidth: 260}, dropdownItemsWrapper: {maxHeight: 360}}}
                            />                   
                        </div> 
                    }</PurposeReader> 
            },   
            ...(historyName === 'dashboard' || historyName === 'pending' ? [{
                id: 'customerDocIndex' as FiltersType,
                component:  
                <CustomerDocIndexReader>{(reduxValue?: string) =>   
                    <>
                        <TextField 
                            key={'customerDocIndex'}
                            label={t('Customer Doc.Index')}
                            autoComplete={'off'} 
                            value={reduxValue || ''} 
                            onChange={(e, value) => { 
                                setCustomerDocIndex({value});
                                this.debouncedFetchDocuments();
                            }}
                            styles={{root: {marginLeft: 5, minWidth: 260}}}
                        />           
                    </>
                }</CustomerDocIndexReader>  
            }] : []),                    
            ...(historyName === 'dashboard' || historyName === 'pending' ? [{
                id: 'customerDocNumber' as FiltersType,
                component:  
                <CustomerDocNumberReader>{(reduxValue?: string) =>   
                    <>
                        <TextField 
                            key={'customerDocNumber'}
                            label={t('Customer Doc.Number')}
                            autoComplete={'off'} 
                            value={reduxValue || ''} 
                            onChange={(e, value) => { 
                                setCustomerDocNumber({value});
                                this.debouncedFetchDocuments();
                            }}
                            styles={{root: {marginLeft: 5, minWidth: 260}}}
                        />             
                    </>
                }</CustomerDocNumberReader>  
            }] : []),     
            ...(historyName !== 'dashboard' && historyName !== 'pending' ? [{
                id: 'supplierDocIndex' as FiltersType,
                component:  
                <SupplierDocIndexReader>{(reduxValue?: string) =>   
                    <>
                        <TextField 
                            key={'supplierDocIndex'}
                            label={t('Supplier Doc.Index')}
                            autoComplete={'off'} 
                            value={reduxValue || ''} 
                            onChange={(e, value) => { 
                                setSupplierDocIndex({value});
                                this.debouncedFetchDocuments();
                            }}
                            styles={{root: {marginLeft: 5, minWidth: 260}}}
                        />           
                    </>
                }</SupplierDocIndexReader>  
            }] : []),                    
            ...(historyName !== 'dashboard' && historyName !== 'pending' ? [{
                id: 'supplierDocNumber' as FiltersType,
                component:  
                <SupplierDocNumberReader>{(reduxValue?: string) =>   
                    <>
                        <TextField 
                            key={'supplierDocNumber'}
                            label={t('Supplier Doc.Number')}
                            autoComplete={'off'} 
                            value={reduxValue || ''} 
                            onChange={(e, value) => { 
                                setSupplierDocNumber({value});
                                this.debouncedFetchDocuments();
                            }}
                            styles={{root: {marginLeft: 5, minWidth: 260}}}
                        />             
                    </>
                }</SupplierDocNumberReader>  
            }] : []),                    
            {
                id: 'sapPart' as FiltersType,
                component:  
                <SapPartReader>{(reduxValue?: string) =>   
                    <>
                        <TextField 
                            key={'sapPart'}
                            label={t('SAP Doc.Part')}
                            autoComplete={'off'} 
                            value={reduxValue || ''} 
                            onChange={(e, value) => { 
                                setSapPart({value});
                                this.debouncedFetchDocuments();
                            }}
                            styles={{root: {marginLeft: 5, minWidth: 260}}}
                        />             
                    </>
                }</SapPartReader>  
            },
            {
                id: 'sapType' as FiltersType,
                component:  
                <SapTypeReader>{(reduxValue?: string) =>   
                    <>
                        <TextField 
                            key={'sapType'}
                            label={t('SAP Type')}
                            autoComplete={'off'} 
                            value={reduxValue || ''} 
                            onChange={(e, value) => { 
                                setSapType({value});
                                this.debouncedFetchDocuments();
                            }}
                            styles={{root: {marginLeft: 5, minWidth: 260}}}
                        />             
                    </>
                }</SapTypeReader>  
            },
            {
                id: 'language' as FiltersType,
                component:  
                <LanguageReader>{(reduxValue?: string) =>   
                    <>
                        <TextField 
                            key={'language'}
                            label={t('Language')}
                            autoComplete={'off'} 
                            value={reduxValue || ''} 
                            onChange={(e, value) => { 
                                setLanguage({value});
                                this.debouncedFetchDocuments();
                            }}
                            styles={{root: {marginLeft: 5, minWidth: 260}}}
                        />             
                    </>
                }</LanguageReader>  
            },
            {
                id: 'projectDocumentNumber' as FiltersType,
                component:  
                <ProjectDocumentNumberReader>{(reduxValue?: string) =>   
                    <>
                        <TextField 
                            key={'projectDocumentNumber'}
                            label={t('Project Doc.Number')}
                            autoComplete={'off'} 
                            value={reduxValue || ''} 
                            onChange={(e, value) => { 
                                setProjectDocumentNumber({value});
                                this.debouncedFetchDocuments();
                            }}
                            styles={{root: {marginLeft: 5, minWidth: 260}}}
                        />             
                    </>
                }</ProjectDocumentNumberReader>  
            },
            // ...(!_.some(userProjectActions, a => a === 'FilterDocumentsByPartnerId')  ? [{
            //     id: 'publication' as FiltersType,
            //     component:  
            //     <TransmittalNumberReader>{(reduxValue?: number) =>   
            //         <>
            //             <Label style={{marginLeft: 5}}>{t('Public.Number')}</Label>
            //             <NumberFormat
            //                 customInput={TextField}
            //                 decimalScale={0}
            //                 value={(reduxValue) ? reduxValue : ''}
            //                 autoComplete={'off'}
            //                 onValueChange={(values) => {
            //                     setTransmittalId({value: values.floatValue});
            //                     this.debouncedFetchDocuments();
            //                 }}
            //                 styles={{root: {marginLeft: 5, minWidth: 260}}}
            //             />               
            //         </>
            //     }</TransmittalNumberReader>  
            // }] : [])     
        ];
        filtersComponents = getContextualFilters([statusComponent, ...datesComponent, ...filtersComponents], historyName);
        return (isCallout && selectedFilter && selectedFilter) ? (selectedFilter !== 'dates_callout' ? _.filter(filtersComponents, f => f.id === selectedFilter) : [...datesComponent]) : filtersComponents;     
    }

    onRenderPbsSuffix: IRenderFunction<ITextFieldProps> = (props, defaultRender) => {
        return (      
            <PbsReader>{(reduxValue?: Pbs) =>   
                <TooltipHost directionalHint={DirectionalHint.rightCenter} content={(reduxValue) ? "Remove PBS" : ""}>
                    <div onClick={(reduxValue) ? (e) => {this.setPbsValueFunction(undefined); e.stopPropagation()} : () => {}}>
                        <FontIcon iconName={"Cancel"} style={{cursor: (reduxValue) ? "pointer" : "default" }}  /> 
                    </div>
                </TooltipHost>          
            }</PbsReader>  
        )
    };

    setPbsValueFunction = (item?: any, checked?: any) =>{
        const { setPbs } = this.props;
        if(item)
            setPbs({value: (checked) ? item : undefined});
        else 
            setPbs({value: undefined});
        this.debouncedFetchDocuments();
    }

    getOtherFilters = (): FilterComponent[] => {
        const { isCallout, history, types, loadingTypes, loadingPurposes, loadingEntities, loadingEngPhases, purposes, entities, pbs, loadingPbs, discipline, partners, loadingStakeHolders, customers, t, userProjectActions, darkModeEnabled, suppliers,
                engPhases, disciplines, loadingDisciplinesAndDocTypes, setType, setSupplier, setEngPhase, setEntity, setPwFilename, setCommented, setDiscipline, setDocType, setIsForInternalUse, setShowAllVersions, setPartner, setCustomer, setFileType } = this.props;
        const historyName = getHistoryName(history);
        const styles = getClassNames(isCallout ? true : false, getTheme(darkModeEnabled));
        let type_options: {key: number, text: string}[] = [{key: -1, text: 'None'}, ...getTypesDropdownOptions(types)];    
        let entities_options: {key: number, text: string}[] = [{key: -1, text: 'None'}, ...getEntityDropdownOptions(entities)];
        let partner_options: {key: number, text: string}[] = [{key: -1, text: 'None'}, ...getStakeHoldersDropdownOptions(partners)];
        let customer_options: {key: number, text: string}[] = [{key: -1, text: 'None'}, ...getStakeHoldersDropdownOptions(_.filter(customers, c => c.isActive))];
        let supplier_options: {key: number, text: string}[] = [{key: -1, text: 'None'}, ...getStakeHoldersDropdownOptions(_.filter(suppliers, c => c.isActive))];
        let engPhases_options: {key: number, text: string}[] = [{key: -1, text: 'None'}, ...getEngPhaseDropdownOptions(engPhases)];
        let disciplines_options: {key: number, text: string}[] = [{key: -1, text: 'None'}, ...getDisciplineDropdownOptions(disciplines)];
        let docType_options: {key: number, text: string}[] = (disciplines.length > 0 && discipline) ? [{key: -1, text: 'None'}, ...getDocTypesDropdownOptions(discipline)] : [];
        let fileTypes_options: {key: string, text: string}[] = [{key: '', text: 'None'}, ...getFileTypesDropdownOptions()];
        let filtersComponents: FilterComponent[] = [
            {
                id: 'pbs',
                component:
                <PbsReader>{(reduxValue?: Pbs) =>   
                    <HierarchyExplorer 
                        readOnly={loadingPbs}
                        title={"PBS selector"}
                        items={pbs}
                        onSelect={(item, checked) => this.setPbsValueFunction(item, checked)}                                             
                        selected={(reduxValue) ? [reduxValue.name] : []}
                        selectedProperty={"name"}
                        isSelectable={() => {return true}}>
                        {(ref, onClick) => (
                            <div style={{marginLeft: 5}}>
                                {!loadingPbs && <Label>PBS</Label>}
                                {loadingPbs && 
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <Label style={{marginRight: 10}}>PBS</Label>
                                        <Spinner size={SpinnerSize.small}/>
                                    </div>
                                }
                                <div ref={ref} onClick={onClick} onFocus={onClick}>
                                    <TextField 
                                        readOnly={true} 
                                        autoComplete={'off'} 
                                        value={(reduxValue) ? reduxValue.name : undefined} 
                                        title={(reduxValue) ? reduxValue.name : undefined} 
                                        onRenderSuffix={this.onRenderPbsSuffix}
                                    />      
                                </div>
                            </div>
                        )}
                    </HierarchyExplorer>
                }</PbsReader>  
            },
            {
                id: 'type',
                component: 
                    <TypeReader>{(reduxValue?: Type) =>   
                        <div style={{marginLeft: 5}}>
                            {!loadingTypes && <Label>Type</Label>}
                            {loadingTypes && 
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Label style={{marginRight: 10}}>Type</Label>
                                    <Spinner size={SpinnerSize.small}/>
                                </div>
                            }
                            <Dropdown
                                key={'type'}
                                placeholder={t('Select a type') + '...'}
                                disabled={loadingTypes}
                                options={type_options}
                                onChange={(e, value) => {
                                    setType({value: (value && value.key !== -1) ? _.find(types, dt => dt.id === value.key) : undefined});
                                    this.debouncedFetchDocuments();
                                }}
                                selectedKey={(reduxValue) ? reduxValue.id : null}                              
                                styles={{root: {minWidth: 260}, dropdownItemsWrapper: {maxHeight: 360}}}
                            />                   
                        </div> 
                    }</TypeReader>  
            },
            {
                id: 'discipline',
                component: 
                    <div style={{marginLeft: 5}}>
                        {!loadingDisciplinesAndDocTypes && <Label>{t('Discipline')}</Label>}
                        {loadingDisciplinesAndDocTypes && 
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Label style={{marginRight: 10}}>{t('Discipline')}</Label>
                                <Spinner size={SpinnerSize.small}/>
                            </div>
                        }
                        <Dropdown
                            key={'discipline'}
                            placeholder={t('Select a discipline') + '...'}
                            disabled={loadingDisciplinesAndDocTypes || disciplines.length === 0}
                            options={disciplines_options}
                            onChange={(e, value) => {
                                setDiscipline({value: (value && value.key !== -1) ? _.find(disciplines, dt => dt.id === value.key) : undefined});
                                this.debouncedFetchDocuments();
                            }}
                            selectedKey={(discipline) ? discipline.id : null}                              
                            styles={{root: {minWidth: 260}, dropdownItemsWrapper: {maxHeight: 360}}}
                        />                   
                    </div> 
            },
            {
                id: 'docType',
                component: 
                    <DocTypeReader>{(reduxValue?: DocType) =>   
                        <div style={{marginLeft: 5}}>
                            {!loadingDisciplinesAndDocTypes && <Label style={{marginLeft: 18}}>{t('Doc Type')}</Label>}
                            {loadingDisciplinesAndDocTypes && 
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Label style={{marginLeft: 18, marginRight: 10}}>{t('Doc Type')}</Label>
                                    <Spinner size={SpinnerSize.small}/>
                                </div>
                            }
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img src={CornerArrow} alt="Can't load search results" style={{marginRight: 5, color: getTheme(darkModeEnabled).palette.themePrimary, height: 20}}/>
                                <Dropdown
                                    key={'docType'}
                                    placeholder={t('Select a doc type') + '...'}
                                    disabled={loadingDisciplinesAndDocTypes || disciplines.length === 0 || !discipline}
                                    options={docType_options}
                                    onChange={(e, value) => {
                                        setDocType({value: (value && value.key !== -1 && discipline) ? _.find(discipline.docTypes, dt => dt.id === value.key) : undefined});
                                        this.debouncedFetchDocuments();
                                    }}
                                    selectedKey={(reduxValue) ? reduxValue.id : null}                              
                                    styles={{root: {minWidth: 260, flexGrow: 1}, dropdownItemsWrapper: {maxHeight: 360}}}
                                />                   
                            </div>
                        </div>
                    }</DocTypeReader>   
            },
            {
                id: 'engPhase',
                component: 
                    <EngPhaseReader>{(reduxValue?: EngPhase) =>   
                        <div style={{marginLeft: 5}}>
                            {!loadingEngPhases && <Label>{t('Eng.Phase')}</Label>}
                            {loadingEngPhases && 
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Label style={{marginRight: 10}}>{t('Eng.Phase')}</Label>
                                    <Spinner size={SpinnerSize.small}/>
                                </div>
                            }
                            <Dropdown
                                key={'engPhase'}
                                placeholder={t('Select an Eng.phase') + '...'}
                                disabled={loadingEngPhases}
                                options={engPhases_options}
                                onChange={(e, value) => {
                                    setEngPhase({value: (value && value.key !== -1) ? _.find(engPhases, p => p.id === value.key) : undefined});
                                    this.debouncedFetchDocuments();
                                }}
                                selectedKey={(reduxValue) ? reduxValue.id : null}                              
                                styles={{root: {minWidth: 260}, dropdownItemsWrapper: {maxHeight: 360}}}
                            />                   
                        </div> 
                    }</EngPhaseReader>   
            },   
            ...(!_.some(userProjectActions, a => a === 'FilterDocumentsByPartnerId')  ? [{
                id: 'entity' as FiltersType,
                component: 
                    <EntityReader>{(reduxValue?: Entity) =>   
                        <div style={{marginLeft: 5}}>
                            {!loadingEntities && <Label>{t('Entity')}</Label>}
                            {loadingEntities && 
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Label style={{marginRight: 10}}>{t('Entity')}</Label>
                                    <Spinner size={SpinnerSize.small}/>
                                </div>
                            }
                            <Dropdown
                                key={'entity'}
                                placeholder={t('Select an entity') + '...'}
                                disabled={loadingEntities}
                                options={entities_options}
                                onChange={(e, value) => {
                                    setEntity({value: (value && value.key !== -1) ? _.find(entities, e => e.id === value.key) : undefined});
                                    this.debouncedFetchDocuments();
                                }}
                                selectedKey={(reduxValue) ? reduxValue.id : null}                              
                                styles={{root: {minWidth: 260}, dropdownItemsWrapper: {maxHeight: 360}}}
                            />                   
                        </div> 
                    }</EntityReader> 
                
            }] : []),                    
            // {
            //     id: 'pwFilename',
            //     component:  
            //         <PwFilenameReader>{(reduxValue?: string) => 
            //             <TextField 
            //                 key={'pwFilename'}
            //                 label={t('PW Filename')}
            //                 autoComplete={'off'} 
            //                 value={reduxValue || ''} 
            //                 onChange={(e, value) => { 
            //                     setPwFilename({value});
            //                     this.debouncedFetchDocuments();
            //                 }}
            //                 styles={{root: {marginLeft: 5, minWidth: 260}}}
            //             /> 
            //         }</PwFilenameReader>                   
            // },    
            ...(isExternalApp ? [{
                id: 'partner' as FiltersType,
                component: 
                    <PartnerReader>{(reduxValue?: StakeHolder) =>   
                        <div style={{marginLeft: 5}}>
                            {!loadingStakeHolders && <Label>Partner</Label>}
                            {loadingStakeHolders && 
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Label style={{marginRight: 10}}>Partner</Label>
                                    <Spinner size={SpinnerSize.small}/>
                                </div>
                            }
                            <Dropdown
                                key={'partner'}
                                placeholder='Select a partner...'
                                disabled={loadingStakeHolders}
                                options={partner_options}
                                onChange={(e, value) => {
                                    setPartner({value: (value && value.key !== -1) ? _.find(partners, dt => dt.stakeHolderId === value.key) : undefined});
                                    this.debouncedFetchDocuments();
                                }}
                                selectedKey={(reduxValue) ? reduxValue.stakeHolderId : null}                              
                                styles={{root: {minWidth: 260}, dropdownItemsWrapper: {maxHeight: 360}}}
                            />                   
                        </div> 
                    }</PartnerReader>  
            }] : []), 
            ...(!isExternalApp && historyName !== 'reservedNumbers' ? [{
                id: 'customer' as FiltersType,
                component: 
                    <CustomerReader>{(reduxValue?: StakeHolder) =>   
                        <div style={{marginLeft: 5}}>
                            {!loadingStakeHolders && <Label>{t('Published to')}</Label>}
                            {loadingStakeHolders && 
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Label style={{marginRight: 10}}>{t('Published to')}</Label>
                                    <Spinner size={SpinnerSize.small}/>
                                </div>
                            }
                            <Dropdown
                                key={'customer'}
                                placeholder={t('Select a customer...')}
                                disabled={loadingStakeHolders}
                                options={customer_options}
                                onChange={(e, value) => {
                                    setCustomer({value: (value && value.key !== -1) ? _.find(customers, dt => dt.stakeHolderId === value.key) : undefined});
                                    this.debouncedFetchDocuments();
                                }}
                                selectedKey={(reduxValue) ? reduxValue.stakeHolderId : null}                              
                                styles={{root: {minWidth: 260}, dropdownItemsWrapper: {maxHeight: 360}}}
                            />                   
                        </div> 
                    }</CustomerReader>  
            }] : []), 
            ...(!isExternalApp && (historyName === 'reservedNumbers' || historyName === 'supplierMaterial') ? [{
                id: 'supplier' as FiltersType,
                component: 
                    <SupplierReader>{(reduxValue?: StakeHolder) =>   
                        <div style={{marginLeft: 5}}>
                            {!loadingStakeHolders && <Label>{t('Supplier')}</Label>}
                            {loadingStakeHolders && 
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Label style={{marginRight: 10}}>{t('Supplier')}</Label>
                                    <Spinner size={SpinnerSize.small}/>
                                </div>
                            }
                            <Dropdown
                                key={'customer'}
                                placeholder={t('Select a supplier...')}
                                disabled={loadingStakeHolders}
                                options={supplier_options}
                                onChange={(e, value) => {
                                    setSupplier({value: (value && value.key !== -1) ? _.find(suppliers, dt => dt.stakeHolderId === value.key) : undefined});
                                    this.debouncedFetchDocuments();
                                }}
                                selectedKey={(reduxValue) ? reduxValue.stakeHolderId : null}                              
                                styles={{root: {minWidth: 260}, dropdownItemsWrapper: {maxHeight: 360}}}
                            />                   
                        </div> 
                    }</SupplierReader>  
            }] : []),   
            ...( historyName === 'reservedNumbers' ? [{
                id: 'fileType' as FiltersType,
                component: 
                    <FileTypeReader>{(reduxValue?: string) =>   
                        <div style={{marginLeft: 5}}>
                            <Label>{t('File type')}</Label>                          
                            <Dropdown
                                key={'fileType'}
                                placeholder={t('Select a file type...')}
                                options={fileTypes_options}
                                onChange={(e, value) => {
                                    setFileType({value: (value && value.key !== '') ? value.key as string : undefined});
                                    this.debouncedFetchDocuments();
                                }}
                                selectedKey={(reduxValue) ? reduxValue : null}                              
                                styles={{root: {minWidth: 260}, dropdownItemsWrapper: {maxHeight: 360}}}
                            />                   
                        </div> 
                    }</FileTypeReader>  
            }] : []),       
            {
                id: 'commented',
                component:
                    <CommentedReader>{(reduxValue: boolean) => 
                        <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
                            <Checkbox 
                                checked={(reduxValue) ? reduxValue : false} 
                                onChange={(e, value) => {
                                    setCommented({value: (value) ? true : false});
                                    this.debouncedFetchDocuments();
                                }} 
                                styles={{root: {marginLeft: 5}}}
                            />                           
                            <Label styles={{root: {marginLeft: 5}}}>{t('Commented')}</Label>
                        </div> 
                    }</CommentedReader>     
            },
            {
                id: 'showAllVersions',
                component:
                    <ShowAllVersionsReader>{(reduxValue: boolean) => 
                        <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
                            <Checkbox 
                                checked={(reduxValue) ? reduxValue : false} 
                                onChange={(e, value) => {
                                    setShowAllVersions({value: (value) ? true : false});
                                    this.debouncedFetchDocuments();
                                }} 
                                styles={{root: {marginLeft: 5}}}
                            />                           
                            <Label styles={{root: {marginLeft: 5}}}>{t('Show all versions')}</Label>
                        </div> 
                     }</ShowAllVersionsReader>   
            },
            // ...(!_.some(userProjectActions, a => a === 'FilterDocumentsByPartnerId')  ? [{
            //     id: 'isForInternalUse'as FiltersType,
            //     component:
            //         <IsForInternalUseReader>{(reduxValue: boolean) => 
            //             <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
            //                 <Checkbox 
            //                     checked={(reduxValue) ? reduxValue : false} 
            //                     onChange={(e, value) => {
            //                         setIsForInternalUse({value: (value) ? true : false});
            //                         this.debouncedFetchDocuments();
            //                     }} 
            //                     styles={{root: {marginLeft: 5}}}
            //                 />                           
            //                 <Label styles={{root: {marginLeft: 5}}}>{t('Show TD cover page')}</Label>
            //             </div> 
            //         }</IsForInternalUseReader>   
            // }] : []), 
        ]
        filtersComponents = getContextualFilters(filtersComponents, historyName);
        return filtersComponents;     
    }

    render() {
        const { isCallout, selectedFilter, darkModeEnabled } = this.props;
        const styles = getClassNames(isCallout ? true : false, getTheme(darkModeEnabled));
        const mainFilterComponents = this.getMainFilters();
        const otherFilterComponents = this.getOtherFilters();
        const dates = _.filter(mainFilterComponents, f => f.id === 'from' || f.id === 'to');
        const checkboxes = _.filter(otherFilterComponents, f => f.id === 'commented' || f.id === 'showAllVersions');
        const genericComponent = this.getGenericComponent();
        return (
            <>
                {!isCallout && 
                    <Scrollbars id={'filters_scrollbar'} style={{ height: '87vh' }}>                       
                        <div className={styles.filtersContainer}>
                                <div key={'_generic'}>{genericComponent.component}</div>
                                {_.map(mainFilterComponents, (fc, index) => {
                                        return (fc.id !== 'from' && fc.id !== 'to' && fc.id !== 'commented' && fc.id!== 'showAllVersions') ? 
                                            <div key={index + '_o'}>{fc.component}</div> : 
                                            ((fc.id === 'from') ? 
                                                <div key={index + '_od'} style={{display: 'flex', alignItems: 'center'}}> {_.map(dates, (df, df_index) => { return <div key={df_index + '_d'} style={{width: '100%'}}>{df.component}</div>})}</div> : 

                                                ((fc.id === 'commented') ? 
                                                  (<div key={index + '_od'} style={{display: 'flex', alignItems: 'center'}}> {_.map(checkboxes, (c, c_index) => { return <div key={c_index + '_c'} style={{width: '100%'}}>{c.component}</div>})}</div>) :
                                                  <div key={'none_' + index}></div>
                                                )
                                            
                                            )
                                })}                        
                                <>
                                    {/* <Separator/> */}
                                    {_.map(otherFilterComponents, (fc, index) => {
                                        return (fc.id !== 'from' && fc.id !== 'to' && fc.id !== 'commented' && fc.id!== 'showAllVersions') ? 
                                            <div key={index + '_o'}>{fc.component}</div> : 
                                            ((fc.id === 'from') ? 
                                                <div key={index + '_od'} style={{display: 'flex', alignItems: 'center'}}> {_.map(dates, (df, df_index) => { return <div key={df_index + '_d'} style={{width: '100%'}}>{df.component}</div>})}</div> : 

                                                ((fc.id === 'commented') ? 
                                                  (<div key={index + '_od'} style={{display: 'flex', alignItems: 'center'}}> {_.map(checkboxes, (c, c_index) => { return <div key={c_index + '_c'} style={{width: '100%'}}>{c.component}</div>})}</div>) :
                                                  <div key={'none_' + index}></div>
                                                )
                                            
                                            )
                                    })}
                                </>      
                        </div>
                    </Scrollbars>    
                }
                {isCallout &&               
                    <div className={styles.filtersContainer}>
                        {selectedFilter && selectedFilter !== 'dates_callout' &&
                            <>
                                {mainFilterComponents[0].component}                                                   
                            </>
                        }
                        {selectedFilter && selectedFilter === 'dates_callout' &&
                            <>
                                {mainFilterComponents[0].component}    
                                {mainFilterComponents[1].component}                                                   
                            </>
                        }
                    </div>
                }
            </>
        );
    }
}


const mapStateToProps = createStructuredSelector({
    userProjectActions: getSelectedProjectUserActions,
    statusValues: getStatusValues,
    types: getTypes,
    purposes: getPurposes,
    entities: getEntities,
    engPhases: getEngPhases,
    disciplines: getDisciplines,
    pbs: getPbs,
    discipline: getFilterDiscipline,
    from: getFilterFrom,
    to: getFilterTo,
    documentNumber: getFilterDocumentNumber,
    selectedProject: getSelectedProject,
    folders: getFolders,
    partners: getSelectedProjectPartners,
    customers: getSelectedProjectCustomers,
    suppliers: getSelectedProjectSuppliers,
    fileType: getFilterFileType,
    darkModeEnabled: getDarkModeEnabled,
    tosId: getFilterTosId,
    loadingStatus: (state) => getLoader('shared')(state).loadingStatus,
    loadingTypes: (state) => getLoader('shared')(state).loadingTypes,
    loadingEngPhases: (state) => getLoader('shared')(state).loadingEngPhases,
    loadingDisciplinesAndDocTypes: (state) => getLoader('shared')(state).loadingDisciplinesAndDocTypes,
    loadingPurposes: (state) => getLoader('projectShared')(state).loadingPurposes,
    loadingEntities: (state) => getLoader('projectShared')(state).loadingEntities,
    loadingPbs: (state) => getLoader('projectShared')(state).loadingPbs,
    loadingFolders: (state) => getLoader('projectShared')(state).loadingFolders,
    loadingStakeHolders: (state) => getLoader('projectShared')(state).loadingStakeHolders,
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({ 
        setStatus: CommandActions.setStatus,
        setDocumentNumber: CommandActions.setDocumentNumber,   
        fetchDocuments: DocumentsCommandActions.fetchDocuments,
        setSheet: CommandActions.setSheet,
        setDocumentIndex: CommandActions.setDocumentIndex,
        setClientNumber: CommandActions.setClientNumber,
        setTdNumber: CommandActions.setTdNumber,
        setTransmittalId: CommandActions.setTransmittalId,
        setTransmittalNumber:  CommandActions.setTransmittalNumber,
        setType: CommandActions.setType,
        setPurpose: CommandActions.setPurpose,
        setEntity: CommandActions.setEntity,
        setEngPhase: CommandActions.setEngPhase,
        setFrom: CommandActions.setFrom,
        setTo: CommandActions.setTo,
        setPwFilename: CommandActions.setPwFilename,
        setCommented: CommandActions.setCommented,
        setDiscipline: CommandActions.setDiscipline,
        setDocType: CommandActions.setDocType,
        setPbs: CommandActions.setPbs,
        setFolder: CommandActions.setFolder,
        setIsForInternalUse: CommandActions.setIsForInternalUse,
        setShowAllVersions: CommandActions.setShowAllVersions,
        setPartner: CommandActions.setPartner,
        setCustomer: CommandActions.setCustomer,
        setSapPart: CommandActions.setSapPart,
        setSapType: CommandActions.setSapType,
        setLanguage: CommandActions.setLanguage,
        setFileType: CommandActions.setFileType,
        setProjectDocumentNumber: CommandActions.setProjectDocumentNumber,
        openGenericModal: ModalCommandActions.openModal,
        setSupplier: CommandActions.setSupplier,
        setSupplierDocIndex: CommandActions.setSupplierDocIndex,
        setSupplierDocNumber: CommandActions.setSupplierDocNumber,
        setCustomerDocIndex: CommandActions.setCustomerDocIndex,
        setCustomerDocNumber: CommandActions.setCustomerDocNumber,
        setTosId: CommandActions.setTosId,
        setGeneric: CommandActions.setGeneric,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)((withTranslation()(withRouter(FiltersClass))));
